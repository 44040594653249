"use client";
import React from "react";
import HomeCard from "./HomeCard";
import { useSelector } from "react-redux";
import { DailyArticle, Articles } from "@/app/_types";
import { RootState } from "../../reducers/store";
import { readingListCompletionDeep } from "@/app/_utils/article";
import { randomNumber } from "@/app/_utils/helper";
import Image from "next/image";
import { stripLink } from "@/app/_utils/formatter";

const HomeCards = () => {
  const readingLists = useSelector((state: RootState) => state.readingLists);
  const userReadArticles = useSelector(
    (state: RootState) => state.userReadArticles,
  );
  const readingListData = readingLists.readingLists["manifestobychapter"];
  const { userReadCount, allArticlesCount } = readingListCompletionDeep(
    readingListData,
    userReadArticles.articles,
  );

  const articles: Articles = useSelector((state: RootState) => state.articles);
  const dailyArticle: DailyArticle = useSelector(
    (state: RootState) => state.dailyArticle,
  );
  let articleId = dailyArticle.article.id;
  let article = articles.articles[articleId];

  if (!article) {
    const keys = Object.keys(articles.articles);
    const excludeKeys = keys.filter((key: string) => {
      if (!articles.articles[key].status) {
        // if there is no status data, do not exclude
        return false;
      } else {
        return (
          articles.articles[key].status?.vault ||
          !articles.articles[key].status.published
        );
      }
    });
    const filteredKeys = keys.filter((key) => !excludeKeys.includes(key));
    const randomArticleIndex = randomNumber(filteredKeys.length - 1);
    articleId = filteredKeys[randomArticleIndex];
    article = articles.articles[randomArticleIndex];
  }

  const month = new Date().toLocaleString("en-US", { month: "long" });

  return (
    <div
      id="homeCards"
      className="grid lg:grid-cols-3 gap-6 py-10 px-5 sm:px-10 pb-5 sm:pb-10"
    >
      <HomeCard
        graphic={
          <Image
            src="/assets/images/learning/book.svg"
            width={80}
            height={80}
            alt=""
          />
        }
        href="/reading-list/manifestobychapter"
        title="MANIFESTO"
        subtitle={
          readingListData
            ? `${userReadCount} out of ${allArticlesCount} articles read`
            : `\u00A0`
        }
        buttonText="Dive In!"
      />
      <HomeCard
        graphic={
          <Image
            src="/assets/images/icons/home/article.svg"
            width={80}
            height={80}
            alt=""
          />
        }
        href={`/reading-list/manifestobychapter/article/${articleId}?chapterDepth=0&isDailyArticle=true`}
        title="DAILY ARTICLE"
        subtitle={article?.title ? stripLink(article.title) : ""}
        buttonText="Don’t Miss It!"
      />
      <HomeCard
        graphic={
          <div className="h-20 w-20 flex justify-center">
            <Image
              src="/assets/images/illustrations/vegx-header-sm.svg"
              height={80}
              width={80}
              alt=""
            />
          </div>
        }
        href="/vegxercise"
        title={
          <>
            <span className="font-bold">VEGXERCISE</span>
          </>
        }
        subtitle={month}
        buttonText="Check It Out!"
      />
    </div>
  );
};

export default HomeCards;
